<template>
  <layout-vertical ref="layoutVertical">
    <keep-alive :include="cachedPages">
      <router-view />
    </keep-alive>
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import { $themeConfig } from '@themeConfig'
import common from '@/common'
import { mapState } from "vuex";

export default {
  components: {
    LayoutVertical,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
  computed: {
    ...mapState('app', ['cachedPages'])
  },
  methods: {
    // 處理左側導航菜單 icons
    handleNavMenuBarIcons() {
        const thisElm = this.$refs.layoutVertical.$el
        const ulElm = thisElm.querySelector('ul.navigation-main')
        const menuItems = common.getMenuItems()

        // 將 menuItem 的 code 添加到 li 元素上，用以替換 icon
        function addMenuItemTagToElms(items, liElms) {
          try {
            for (let i = 0; i < items.length; i += 1) {
              liElms[i].setAttribute('data-menu-code', items[i].code)
              if (items[i].subs && items[i].subs.length > 0) {
                addMenuItemTagToElms(items[i].subs, liElms[i].childNodes[1].childNodes)
              }
            }
          } catch(error) {
            console.error('Error while handling NavMenuBarIcons:\n', error);
          }
        }

        addMenuItemTagToElms(menuItems, ulElm.childNodes)
    }
  },
  mounted() {
    this.handleNavMenuBarIcons()
  }
}
</script>

<style lang="scss">
// 處理左側導航菜單 icons
#app ul.navigation-main {
  li[data-menu-code] {
    a {
      display: block;
      position: relative;
      svg {
        visibility: hidden;
      }
    }
    a::before {
      content: '';
      background-position: center center;
      background-size: 3.2rem;
      background-repeat: no-repeat;
      overflow: visible;
      display: inline-block;
      position: absolute;
      left: 0.8rem;
      height: 100%;
      width: 2rem;
      transition: transform 0.25s ease;
      transform: translateX(0px);
    }
    a:hover::before {
      transition: transform 0.25s ease;
      transform: translateX(5px);
    }
  }
  > li > a::before {
    background-image: url("~@/assets/images/icons/icon-00.png");
  }
  li[data-menu-code=Com_Menu] > a::before {
    background-image: url("~@/assets/images/icons/icon-01.png");
  }
  li[data-menu-code=Fac_Menu] > a::before {
    background-image: url("~@/assets/images/icons/icon-02.png");
  }
  li[data-menu-code=Bed_Menu] > a::before {
    background-image: url("~@/assets/images/icons/icon-03.png");
  }
  li[data-menu-code=Sta_Menu] > a::before {
    background-image: url("~@/assets/images/icons/icon-04.png");
  }
  li[data-menu-code=Mem_Menu] > a::before {
    background-image: url("~@/assets/images/icons/icon-05.png");
  }
  li[data-menu-code=Gro_Menu] > a::before {
    background-image: url("~@/assets/images/icons/icon-06.png");
  }
  li[data-menu-code=Role_Menu] > a::before {
    background-image: url("~@/assets/images/icons/icon-07.png");
  }
  li[data-menu-code=Mon_Menu] > a::before {
    background-image: url("~@/assets/images/icons/icon-08.png");
  }
  li[data-menu-code=CallBell_Menu] > a::before {
    background-image: url("~@/assets/images/icons/icon-callbell.png");
    background-size: 110%;
  }
  li[data-menu-code=Notice_Menu] > a::before {
    background-image: url("~@/assets/images/icons/icon-notice.png");
    background-size: 105%;
  }
  li[data-menu-code=Admin_Menu] > a::before {
    background-image: url("~@/assets/images/icons/icon-admin.png");
    background-size: 105%;
  }
}

</style>
